import logoLink from '../resources/sauter.svg';

export const cssVariables = {
    active: '#0082C8',
    inactive: '#99C7ED',

    primary_light: '#99C7ED',
    primary: '#0082C8',
    primary_dark: '#0068a0',
    text_on_primary: '#fff',
    primary_transparent: '#99cde9',

    secondary: '#0082C8',
    secondary_light: '#99C7ED',
    text_on_secondary: '#fff',
    secondary_transparent: '#d6e9f8',

    font_color: 'black',
    link_color: '#0082C8',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#0082C8',
    activate_color: '#b3b3b3',
    deactivate_color: '#b3b3b3',
    delete_color: '#99C7ED',

    dropdown_background: '#99C7ED',

};

export const logo = logoLink;
